import { SectionNav } from '@local/components/';
import { Image } from '@nintendo-of-america/component-library';
import styled from 'styled-components';
import { COLORS } from '../amiiboConstants';

export const HeaderWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQuery.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Logo = styled(Image)`
  padding-top: ${({ theme }) => theme.spacing[16]};
  width: 33%;
  min-width: 142px;
  max-width: 300px;

  ${({ theme }) => theme.mediaQuery.tabletSmall} {
    padding-bottom: ${({ theme }) => theme.spacing[16]};
  }
`;

export const Nav = styled(SectionNav)`
  background-color: transparent;
  overflow: visible;

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding-left: 0;
    padding-right: 0;
    margin: 0;
  }

  a {
    color: white;

    /** Home button */
    &:nth-of-type(1) {
      &::before {
        border-color: ${({ theme }) => theme.color.primary};
        background-color: ${({ theme }) => theme.color.primary};
      }

      &:hover {
        &::before {
          border-color: ${({ theme }) => theme.color.primary};
          background-color: ${({ theme }) => theme.color.primary};
        }
      }
    }

    /** Lineup button */
    &:nth-of-type(2) {
      &::before {
        border-color: ${COLORS.BLUE};
        background-color: ${COLORS.BLUE};
      }

      &:hover {
        &::before {
          border-color: ${COLORS.BLUE};
          background-color: ${COLORS.BLUE};
        }
      }
    }

    /** FAQ button */
    &:nth-of-type(3) {
      &::before {
        background-color: ${COLORS.YELLOW};
        border-color: ${COLORS.YELLOW};
      }

      &:hover {
        &::before {
          background-color: ${COLORS.YELLOW};
          border-color: ${COLORS.YELLOW};
        }
      }
    }
  }
`;
