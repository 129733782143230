import { object, string } from 'prop-types';
import { Section } from '@local/components';
import RichText from '@local/components/RichText';
import { Spacer } from '@nintendo-of-america/component-library';
import * as Amiibo from '../Amiibo.styles';

const AmiiboCallout = ({ heading, body }) => {
  return (
    <Section
      constrained
      small
      background="url(https://assets.nintendo.com/image/upload/f_auto,q_auto/amiibo/2021/amiibo_crowd) center center / cover no-repeat"
    >
      <Spacer size={40} />
      <Amiibo.Header>
        <h2>{heading}</h2>
        <RichText data={body} />
      </Amiibo.Header>
      <Spacer size={40} />
    </Section>
  );
};

AmiiboCallout.propTypes = {
  heading: string,
  body: object,
};

export default AmiiboCallout;
