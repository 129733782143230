import { RichText, Section } from '@local/components';
import { arrayOf, object, string, shape } from 'prop-types';
import { CLOUDINARY_IMAGE } from '@nintendo-of-america/contentful-api/parsers';
import { useTheme } from 'styled-components';
import { COLORS } from '../amiiboConstants';
import * as S from './AmiiboFeatures.styles';
import * as Amiibo from '../Amiibo.styles';

const AmiiboFeatures = ({ heading, body, promos }) => {
  const theme = useTheme();
  const backgrounds = [theme.color.primary, COLORS.GREEN, COLORS.BLUE];
  return (
    <Section
      constrained
      small
      background="url(https://assets.nintendo.com/image/upload/f_auto,q_auto/amiibo/2021/Rectangle)"
    >
      <Amiibo.Header>
        <h2>{heading}</h2>
        <RichText data={body} />
      </Amiibo.Header>
      {promos &&
        promos.map(({ asset, heading, body }, index) => (
          <S.Promo
            key={heading}
            content={{
              assetPath: asset.primary.assetPath,
              assetPathAlt: asset.alt,
              heading,
              description: body,
              background: { backgroundType: backgrounds[index] },
            }}
            headingVariant="h2"
            reverseContent={index % 2 !== 0}
          />
        ))}
    </Section>
  );
};

AmiiboFeatures.propTypes = {
  heading: string,
  body: object,
  promos: arrayOf(
    shape({
      asset: CLOUDINARY_IMAGE,
      heading: string,
      body: object,
    })
  ),
};

export default AmiiboFeatures;
