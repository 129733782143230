import { object, shape, string } from 'prop-types';
import { DIMENSIONS } from '@nintendo-of-america/contentful-api/parsers';
import { Section, HeroImage, RichText } from '@local/components/';
import * as S from './AmiiboHero.styles';
const AmiiboHero = ({ image, heading, body }) => {
  return (
    <Section constrained>
      <HeroImage
        assetPath={image.assetPath}
        alt={image.alt}
        dimensions={image.dimensions}
      />
      <S.TextWrap>
        <h2>{heading}</h2>
        <RichText data={body} />
      </S.TextWrap>
    </Section>
  );
};

AmiiboHero.propTypes = {
  image: shape({
    assetPath: string,
    alt: string,
    dimensions: DIMENSIONS,
  }),
  heading: string,
  body: object,
};

export default AmiiboHero;
