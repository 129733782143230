import styled from 'styled-components';
import { COLORS } from '../amiiboConstants';
import { Text } from '@nintendo-of-america/component-library';

export const DetailsWrap = styled.div`
  border-top: 6px solid ${COLORS.BLUE};
  border-bottom: 1px solid ${COLORS.BLUE};
  padding-top: ${({ theme }) => theme.spacing[36]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
  padding-left: ${({ theme }) => theme.spacing[20]};
  padding-right: ${({ theme }) => theme.spacing[20]};
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 75%,
    rgba(${COLORS.BLUE_RAW}, 0.15) 100%
  );
  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LegalWrap = styled.div`
  * {
    font-size: ${({ theme }) => theme.font.size.legalMobile};

    ${({ theme }) => theme.mediaQuery.tablet} {
      font-size: ${({ theme }) => theme.font.size.legalDesktop};
    }
  }
`;

export const AvailableAtText = styled(Text)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`;
