import { Section } from '@local/components';
import { CLOUDINARY_IMAGE } from '@nintendo-of-america/contentful-api/parsers';
import { Image, Spacer } from '@nintendo-of-america/component-library';
import { arrayOf, shape, string } from 'prop-types';

import * as S from './AmiiboHowTo.styles';

const AmiiboHowTo = ({ tiles }) => {
  return (
    <Section
      constrained
      small
      background="url(https://assets.nintendo.com/image/upload/f_auto,q_auto/amiibo/2021/bg) center center / cover no-repeat"
      divider={['bottom']}
    >
      <S.TilesWrap>
        {tiles &&
          tiles.map((tile) => (
            <div key={tile.heading}>
              <Image assetPath={tile.asset.primary.assetPath} alt={tile.alt} />
              <Spacer size={16} />
              <h3>{tile.heading}</h3>
            </div>
          ))}
      </S.TilesWrap>
    </Section>
  );
};

AmiiboHowTo.propTypes = {
  tiles: arrayOf(
    shape({
      heading: string,
      asset: CLOUDINARY_IMAGE,
    })
  ),
};

export default AmiiboHowTo;
