import styled from 'styled-components';

export const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const CTAWrap = styled.div`
  margin-top: ${({ theme }) => theme.spacing[48]};
  text-align: center;
`;
