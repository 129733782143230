import {
  MediaGallery,
  KeyArt,
  Heading,
  Text,
  Spacer,
  Button,
  MyNintendoStoreLogoIcon,
  ScreenReaderOnly,
} from '@nintendo-of-america/component-library';
import { Grid } from '@shared/ui';

import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { Section } from '@local/components';

import * as S from './AmiiboDetails.styles';
import RichText from '@local/components/RichText';
import { useRouter } from '@nintendo-of-america/next';
const ALLOWABLE_LOCALES_FOR_MY_NINTENDO_STORE = ['en-us', 'en-ca', 'fr-ca'];

const AmiiboDetails = ({ legal, amiibo }) => {
  const { date, DateFormat, text } = useLocalizer();
  const { locale } = useRouter();
  return (
    <Section constrained small>
      {/* Amiibo detail */}
      <S.DetailsWrap>
        <Grid columns={1} tablet={{ columns: 2 }} gap={32}>
          <MediaGallery ariaLabel="Amiibo image gallery">
            <KeyArt assetPath={amiibo.figureAsset.publicId} pad />
            <KeyArt assetPath={amiibo.boxartAsset.publicId} pad />
          </MediaGallery>
          <div>
            <Heading variant="h1" as="h2" large>
              {amiibo.name}
            </Heading>
            {amiibo.series && (
              <>
                <Spacer size={16} />
                <Text variant="body">
                  {text('Series:')} <b>{amiibo.series}</b>
                </Text>
              </>
            )}
            <Text variant="body">
              {text('Release date:')}{' '}
              <b>
                {amiibo.releaseDateDisplay ||
                  date(amiibo.releaseDate, {
                    format: DateFormat.LONG,
                  })}
              </b>
            </Text>
            <Text variant="body">
              {text('Presented by:')} <b>{amiibo.presentedBy}</b>
            </Text>
            <Spacer size={16} />
            <RichText data={amiibo.description.json} />
            {ALLOWABLE_LOCALES_FOR_MY_NINTENDO_STORE.includes(locale) &&
              amiibo?.product?.urlKey &&
              amiibo?.product?.isSalableQty && (
                <>
                  <S.AvailableAtText variant="body">
                    {text('Available at:')}
                  </S.AvailableAtText>
                  <ScreenReaderOnly>
                    {text('My Nintendo Store')}
                  </ScreenReaderOnly>
                  <Button href={`/store/products/${amiibo?.product?.urlKey}`}>
                    <MyNintendoStoreLogoIcon size={188} />
                  </Button>
                </>
              )}
          </div>
        </Grid>
      </S.DetailsWrap>
      <Spacer size={32} />
      {/* Legal */}
      <S.LegalWrap>
        <RichText data={legal} />
      </S.LegalWrap>
    </Section>
  );
};

export default AmiiboDetails;
