import { Section } from '@local/components';
import {
  Heading,
  Spacer,
  Button,
  Text,
  BasicTile,
} from '@nintendo-of-america/component-library';
import { Grid } from '@shared/ui';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import { useCallback, useState } from 'react';
import { FILTER_NAMES } from '../amiiboConstants';

import * as S from './AmiiboLineUp.styles';

const AmiiboLineUp = ({ amiibos, heading }) => {
  const [activeFilter, setActiveFilter] = useState(FILTER_NAMES.RELEASE_DATE);
  const [visibleAmiibos, setVisibleAmiibos] = useState(amiibos);
  const { text, date } = useLocalizer();

  const handleFilterClick = useCallback(
    (name) => {
      const { RELEASE_DATE, NAME } = FILTER_NAMES;

      setVisibleAmiibos(
        visibleAmiibos.sort((a, b) => {
          switch (name) {
            case RELEASE_DATE:
              return (
                new Date(b.releaseDate || b.releaseDateDisplay) -
                new Date(a.releaseDate || a.releaseDateDisplay)
              );
            case NAME:
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
          }
        })
      );

      setActiveFilter(name);
    },
    [visibleAmiibos]
  );

  return (
    <Section constrained small>
      {/* Heading */}
      <Heading variant="h1" large>
        {heading}
      </Heading>
      <Spacer size={24} />
      {/* Filters */}
      <S.SortWrap>
        <Text variant="title">{text('Sort by')}:</Text>
        <Button
          round
          still
          size="small"
          variant="quaternary"
          selected={activeFilter === FILTER_NAMES.RELEASE_DATE}
          onClick={() => handleFilterClick(FILTER_NAMES.RELEASE_DATE)}
        >
          {text('Release date')}
        </Button>
        <Button
          round
          still
          size="small"
          variant={
            activeFilter === FILTER_NAMES.NAME ? 'tertiary' : 'quaternary'
          }
          onClick={() => handleFilterClick(FILTER_NAMES.NAME)}
        >
          {text('Name')}
        </Button>
      </S.SortWrap>
      <Spacer size={32} />
      {/* Amiibo tiles */}
      <Grid gap={16} columns={2} tablet={{ columns: 4, gap: 32 }}>
        {visibleAmiibos.map((amiibo) => (
          <BasicTile
            accessibleLabel={amiibo.name}
            key={amiibo.id}
            artPath={amiibo.figureAsset.publicId}
            tileLink={amiibo.url}
            constrain={false}
          >
            <Heading variant="h3">{amiibo.name}</Heading>
            <Spacer size={amiibo.series ? 8 : 28} />
            {amiibo.series && (
              <Text variant="caption">
                {text('{0} series', { args: [amiibo.series] })}
              </Text>
            )}
            <Text variant="caption">
              {amiibo.releaseDateDisplay ||
                text('Available {0}', {
                  args: [date(amiibo.releaseDate)],
                })}
            </Text>
            <Spacer size={16} />
          </BasicTile>
        ))}
      </Grid>
    </Section>
  );
};

export default AmiiboLineUp;
