import { arrayOf, object, string, shape } from 'prop-types';
import { Section, AccordionRow } from '@local/components';
import RichText from '@local/components/RichText';
import * as S from './AmiiboFaq.styles';

const AmiiboFaq = ({ heading, faqs, deepLink }) => {
  return (
    <Section constrained small id={deepLink}>
      <S.Heading variant="h1" forwardedAs="h2" large>
        {heading}
      </S.Heading>
      {faqs &&
        faqs.map((faq) => (
          <AccordionRow key={faq.heading} heading={faq.heading}>
            <RichText data={faq.body} />
          </AccordionRow>
        ))}
    </Section>
  );
};

AmiiboFaq.propTypes = {
  heading: string,
  deepLink: string,
  faqs: arrayOf(
    shape({
      heading: string,
      body: object,
    })
  ),
};

export default AmiiboFaq;
