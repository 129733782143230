import styled from 'styled-components';
import { StoryModule } from '@local/components';

export const Promo = styled(StoryModule)`
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  padding-top: ${({ theme }) => theme.spacing[32]};
  padding-bottom: ${({ theme }) => theme.spacing[32]};
  padding-left: ${({ theme }) => theme.spacing[32]};
  padding-right: ${({ theme }) => theme.spacing[32]};
  margin-top: ${({ theme }) => theme.spacing[40]};
  margin-bottom: ${({ theme }) => theme.spacing[40]};

  ${({ theme }) => theme.mediaQuery.tablet} {
    padding-left: ${({ theme, reverseContent }) =>
      reverseContent ? theme.spacing[44] : theme.spacing[16]};
    padding-right: ${({ theme, reverseContent }) =>
      reverseContent ? theme.spacing[16] : theme.spacing[44]};
  }
`;
