import { Button, BasicTile } from '@nintendo-of-america/component-library';
import { Section } from '@local/components/';
import * as S from './AmiiboFeatured.styles';
import { arrayOf, shape, string } from 'prop-types';

const AmiiboFeatured = ({ amiibos, cta }) => {
  return (
    <Section constrained small>
      <S.GridWrap>
        {amiibos &&
          amiibos.map((amiibo) => (
            <BasicTile
              key={amiibo.id}
              artPath={amiibo.figureAsset?.publicId}
              tileLink={amiibo.url}
              constrain={false}
            >
              <h3>{amiibo.name}</h3>
            </BasicTile>
          ))}
      </S.GridWrap>
      <S.CTAWrap>
        <Button href={cta.url}>{cta.label}</Button>
      </S.CTAWrap>
    </Section>
  );
};

AmiiboFeatured.propTypes = {
  amiibos: arrayOf(
    shape({
      image: string,
      title: string,
      url: string,
    })
  ),
  cta: shape({
    url: string,
    label: string,
  }),
};

export default AmiiboFeatured;
