import {
  MyNintendoHomeIcon,
  AmiiboIcon,
  SupportIcon,
} from '@nintendo-of-america/component-library';

import { Section } from '@local/components/';
import * as S from './AmiiboHeader.styles';

const AmiiboHeader = ({ logo, navItems }) => {
  return (
    <Section constrained>
      <S.HeaderWrap>
        <S.Logo assetPath={logo.assetPath} alt={logo.alt} />
        <S.Nav
          navItems={[
            {
              title: navItems[0].label,
              url: navItems[0].url,
              icon: <MyNintendoHomeIcon />,
              still: false,
            },
            {
              title: navItems[1].label,
              url: navItems[1].url,
              icon: <AmiiboIcon />,
              still: false,
            },
            {
              title: navItems[2].label,
              url: navItems[2].url,
              icon: <SupportIcon />,
              still: false,
            },
          ]}
          variant="primary"
        />
      </S.HeaderWrap>
    </Section>
  );
};

export default AmiiboHeader;
