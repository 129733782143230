export const COLORS = {
  GREEN: 'rgb(164, 199, 14)',
  BLUE: 'rgb(0, 155, 235)',
  BLUE_RAW: '0, 155, 235',
  YELLOW: 'rgb(245, 170, 0)  ',
};

export const FAQ_DEEPLINK = 'faq';

export const FILTER_NAMES = {
  RELEASE_DATE: 'RELEASE_DATE',
  NAME: 'NAME',
};
