import styled from 'styled-components';
import { COLORS } from './amiiboConstants';

export const Header = styled.div`
  display: block;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadiusRound};
  text-align: center;
  max-width: 850px;
  width: auto;
  padding-top: ${({ theme }) => theme.spacing[24]};
  padding-bottom: ${({ theme }) => theme.spacing[8]};
  padding-left: ${({ theme }) => theme.spacing[24]};
  padding-right: ${({ theme }) => theme.spacing[24]};
  margin: 0 auto;
`;

export const PageWrap = styled.div`
  background-image: url('https://www.nintendo.com/etc.clientlibs/noa/clientlibs/clientlib-ncom/resources/images/page/amiibo/common/sil-blend.jpg');
  background-repeat: repeat-x;
`;

export const DetailsWrap = styled.div`
  border-top: 6px solid ${COLORS.BLUE};
  border-bottom: 1px solid ${COLORS.BLUE};
  padding-top: ${({ theme }) => theme.spacing[36]};
  padding-bottom: ${({ theme }) => theme.spacing[20]};
  padding-left: ${({ theme }) => theme.spacing[20]};
  padding-right: ${({ theme }) => theme.spacing[20]};
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 75%,
    rgba(${COLORS.BLUE_RAW}, 0.15) 100%
  );
`;
